<template>
  <div
    class="comanda add-cos btn btn-red vld-parent"
    v-on:click="addToCart()"
    :class="{ blockBtn: sending }"
  >
    <a>{{ addToCartText }}</a>
    <loading
      :active="sending"
      :is-full-page="false"
      :height="25"
      :width="25"
      :opacity="0.5"
    ></loading>
  </div>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data: () => ({
    addToCartText: "Adaugă în coș",
    sending: false,
  }),
  props: ["offer"],
  components: {
    Loading,
  },
  computed: {
    quantity() {
      return this.$store.getters["shop/quantity"];
    },
  },
  methods: {
    addToCart() {
      this.sending = true;
      this.$store
        .dispatch("shop/addToCart", {
          offer_id: this.offer.id,
          quantity: this.quantity,
        })
        .then(() => {
          // setTimeout(() => {
          this.$store.dispatch("modals/addToCartModal", true);

          this.sending = false;
          // }, 5500);
        });
      this.$gtm.trackEvent({
        event: "add_to_cart", 
        id: this.offer.product_id,
        name: this.offer.name,
        price: this.offer.price_value,
        quantity: this.quantity,
      });
    },
  },
  created() {
    if (window.outerWidth > 768) {
      // this.addToCartText = "Adaugă în coșul de cumpărături";
    }
  },
};
</script>

<style scoped>
.blockBtn {
  pointer-events: none;
}

.comanda.add-cos {
  display: inline-flex;
  background-color: #97040c;
  padding: 0;
  width: 170px;
  margin-left: 20px;
}


@media (max-width: 768px) {

  .comanda.add-cos {
    width: 55%;
    margin-left: 10px;
  }

  .addtocartfixed .comanda.add-cos {
    padding: 6px 0;
    margin-right: 0;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .comanda.add-cos a {
    font-size: 12px;
  }
}
</style>
