<template>
  <div v-if="addtocartnavbar" style="background: #fff" class="addtocartfixed container">
    <div class="row">
      <div class="col-7 price-part">
        <product-price v-if="offer.price_value > 0" :offer="offer" />
        <div v-else><span style="font-family:Raleway Bold">În maxim 24 de ore lucrătoare</span><br/> îți vom răspunde.</div>
      </div>
      <div class="col-5 button-part">
        <AddToCartButton v-if="offer.price_value > 0" :offer="offer" />
        <pf-button
          v-else
          :class="'btn btn-red btn-large comanda-rapida btn-border-radius'"
          @click="showModal"
        >
          Cere oferta
        </pf-button>
      </div>
    </div>
  </div>
</template>

<script>
import ProductPrice from "@/components/product_page/ProductPrice.vue";
import AddToCartButton from "@/components/product_page/AddToCartButton.vue";
import PfButton from "@/components/buttons/PfButton.vue";

export default {
  components: {
    ProductPrice,
    AddToCartButton,
    PfButton,
  },
  props: ["offer"],
  data() {
    return {
      // addtocartnavbar: false,
    };
  },
  computed: {
    addtocartnavbar() {
      return this.$store.getters["shop/addToCartNavbar"];
    },
  },
  methods: {
    async handleScroll() {
      const button = document.getElementById("actions");

      if (button) {
        const offsetButton =
          button.getBoundingClientRect().top + document.documentElement.scrollTop;
        if (window.scrollY > offsetButton && window.outerWidth < 576) {
          this.$store.dispatch("shop/actAddToCartNavbar", true);
          // this.addtocartnavbar = true;
        } else {
          this.$store.dispatch("shop/actAddToCartNavbar", false);
        }
      }
    },
    showModal() {
      this.$store.dispatch("modals/askForOfferState", true);
    },
  },
  // this will register the event when the component is mounted on the DOM
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.price-part {
  font-size: 12px;
  color: #181818;
  font-family: "Raleway";
}

.addtocartfixed {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  box-shadow: 0 0 4px 0 rgba(132, 131, 131, 0.5);
}

.addtocartfixed .price-part {
  padding-right: 0;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.addtocartfixed .button-part {
  align-items: center;
  display: flex;
  padding-left: 0;
}

.addtocartfixed .comanda-rapida {
  margin-bottom: 0;
}
</style>
