<template>
  <vue-final-modal
    v-model="showModal"
    class="option-modal"
    classes="modal-container"
    content-class="modal-content"
    @closed="closed"
  >
    <button type="button" class="close" @click="closeAccessory" aria-label="Close">
      <font-awesome-icon icon="times-circle" transform="shrink-2" />
    </button>
    <span class="modal__title">
      <h4 class="modal-title" id="myModalLabel">
        {{ accessory.name }}
        <span class="option-product">- pentru {{ product.name }}</span>
      </h4>
    </span>
    <div class="modal__content">
      <div class="row">
        <div class="col-12 col-sm-5">
          <div class="modal-image-wrapper">
            <img
              v-if="accessory.preview_image"
              :src="accessory.preview_image"
              :alt="accessory.name"
            />
          </div>
        </div>
        <div class="col-12 col-sm-7">
          <div class="modal-description-wrapper">
            {{ accessory.preview_text }}
          </div>

          <div class="modal-price-wrapper">
            Pret:
            <span class="highlight">{{ accessory?.offers[0]?.price_value }} Lei</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__action">
      <button
        v-if="!wasAdded"
        type="button"
        class="btn btn-red add-option"
        @click="addToCart"
      >
        Adauga in cos
      </button>
      <div v-if="wasAdded" class="row option-added">
        <div class="col-12 option-message-modal">
          <p>Optiunea a fost adaugata in cos</p>
        </div>

        <div class="col-md-6 col-sm-6 col-xs-12">
          <!-- <button type="button" class="btn btn-orange" data-dismiss="modal">Continua cumparaturile</button> -->
          <pf-button
            :class="'btn btn-black btn-default btn-large'"
            @click="closeAccessory"
          >
            Continua cumparaturile
          </pf-button>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <pf-button to="/finalizeaza-cos" :class="'btn btn-green btn-default btn-large'">
            Finalizeaza comanda
          </pf-button>
        </div>
      </div>
    </div>
  </vue-final-modal>

  <div
    v-if="show"
    class="option-item"
    data-target="#modal_option_2470"
    @click="showAccessory"
  >
    <div class="option-img-wrapper">
      <img
        v-if="accessory.preview_image"
        :src="accessory.preview_image"
        :alt="accessory.name"
      />

      <img
        v-else
        src="@/assets/images/chit_admisie_aer_exterior.jpg"
        alt="Set admisie focar - aer extern"
      />
    </div>
    <div class="option-name-wrapper">{{ accessory.name }}</div>
    <div v-if="accessory.offers[0]" class="option-price">
      {{ accessory?.offers[0]?.price_value }} Lei
    </div>
  </div>
</template>

<script>
import PfButton from "@/components/buttons/PfButton.vue";

export default {
  props: ["accessory", "show"],
  components: {
    PfButton,
  },

  data: () => ({
    showModal: false,
    wasAdded: false,
  }),

  computed: {
    product() {
      return this.$store.getters["shop/product"];
    },
  },

  methods: {
    showAccessory() {
      this.showModal = true;
    },
    addToCart() {
      this.$store
        .dispatch("shop/addToCart", {
          offer_id: this.accessory.offers[0].id,
          quantity: 1,
        })
        .then(() => {
          this.wasAdded = true;
        });
    },
    closeAccessory() {
      this.showModal = false;
    },
    closed() {
      this.wasAdded = false;
    },
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  /* padding: 1rem; */
  /* border: 1px solid #e2e8f0; */
  background: #fff;
  width: 500px;
  border-radius: 0;
}
.modal__title h4 {
  margin: 0 2rem 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: #900;
  font-weight: bold;
  margin: 0;
  line-height: 1.42857143;
}

.modal__title {
  border: none;
  padding-bottom: 0;
  text-align: center;
  padding-top: 70px;
  padding-left: 15px;
  padding-right: 15px;
}

.modal__title span.option-product {
  color: #333;
  font-size: 0.8em;
  font-weight: normal;
}

.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
}
.modal__action {
  display: flex;
  margin-left: auto;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
  padding: 15px;
  border-top: 1px solid #e5e5e5;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

button.close {
  position: absolute;
  top: 2px;
  right: 0px;
  color: #fff !important;
  z-index: 10;
  opacity: 1;
  width: 65px;
  height: 65px;
  background-color: #c2c2c2;
  font-size: 50px;
  font-weight: lighter;
  margin-top: -2px;
}

.modal-image-wrapper {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  float: none;
}

.modal-image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.modal-description-wrapper {
  width: 260px;
  /* display: inline-block; */
  height: 180px;
  padding-left: 10px;
  padding-right: 10px;
}

.modal-price-wrapper {
  /* display: inline-block; */
  text-align: right;
  width: 260px;
  font-size: 18px;
  font-weight: bold;
}

span.highlight {
  color: #990000;
  font-weight: bold;
}

.option-message-modal p {
  text-align: center;
  color: #900;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 576px) {
  ::v-deep .modal-content {
    width: auto;
  }

  .modal-description-wrapper,
  .modal-price-wrapper {
    width: 100%;
    height: auto;
  }

  .option-name-wrapper {
    font-weight: 600;
  }
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
