<template>
  <div class="actions" id="actions">
    <change-quantity :quantity="quantity" />
    <AddToCartButton :offer="offer" />

    <div class="pl-0 rate-tbi">
      <RateModal :price="offer.price_value" :rate="offer.rate" />
    </div>
    <AddToCartModal :quantity="quantity" />
  </div>
</template>

<script>
import ChangeQuantity from "@/components/product_page/ChangeQuantity.vue";
import RateModal from "@/components/product_page/RateModal.vue";
import AddToCartButton from "@/components/product_page/AddToCartButton.vue";
import AddToCartModal from "@/components/product_page/AddToCartModal.vue";

export default {
  name: "ActionButtons",
  components: {
    ChangeQuantity,
    RateModal,
    AddToCartButton,
    AddToCartModal,
  },
  data: () => ({
    // showModal: false,
    // quantity: 1,
  }),
  props: ["offer"],
  computed: {
    quantity() {
      return this.$store.getters["shop/quantity"];
    },
  },
};
</script>
<style scoped>
#actions {
  margin-top: 25px;
}
</style>
