<template>
  <input
    type="submit"
    :name="name"
    value="Adauga in cos!"
    class="cumpara comanda add-cos btn btn-red"
    :disabled="disabled"
    :id="id"
  />
</template>

<script>
export default {
  props: ["disabled", "id", "name"],
};
</script>

<style lang="scss" scoped>
.btn.btn-red {
  background-color: #97040c;
  color: #fff;
  font-weight: bold;
  border-radius: 0;
  padding: 6px 30px;
}

.comanda.add-cos {
  position: relative;
  border: none;
  padding: 9px 50px;
  height: 40px;
  margin-bottom: 0px;
}

.btn[disabled] {
  background: #dddddd;
  color: #000;
  cursor: no-drop;
  pointer-events: all;
  color: #fff;
}
</style>
