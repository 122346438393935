<template>
  <div v-if="cart.positions">
    <!-- First modal -->
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
      id="ajax-add-to-cart"
      name="ajax-add-to-cart"
    >
      <button
        type="button"
        class="close modal__close"
        data-dismiss="modal"
        aria-label="Close"
        @click="closeModal"
      >
        <font-awesome-icon icon="times-circle" />
      </button>
      <span class="modal__title">Produsul a fost adaugat in cos</span>
      <!-- <div class="modal__action">
      </div> -->

      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="prod-ajax-wrapper row">
            <div class="ajax-img-wrapper col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <img :src="product.preview_image" :alt="product.name" />
            </div>
            <div class="ajax-prod-details col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="">
                <h4>{{ product.name }}</h4>
              </div>
              <div class="">
                <label class="ajax-label">Cantitate:</label>
                <div class="ajax-value quantity">{{ totalQuantity.quantity }}</div>
              </div>
              <div class="">
                <label class="ajax-label">Pret produs:</label>
                <div class="ajax-value price">
                  {{ lastCartProduct.price_per_unit_value }} lei
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 ajax-minicart">
          <div class="col-md-12 col-xs-12">
            <div class="row subtotal-mini">
              <span class="s_title">Subtotal comanda </span>
              <span class="s_products"
                >({{ cart.quantity }}
                {{ cart.quantity > 1 ? "Produse" : "Produs" }})</span
              >
              <span class="s_price">{{ cart.total_price.price }} Lei</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <button type="button" class="btn btn-orange" @click="closeModal">
                Continua cumparaturile</button
              ><font-awesome-icon icon="angle-left" />
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
              <router-link to="/cos-cumparaturi" class="btn btn-red final-order"
                ><font-awesome-icon icon="angle-right" />Spre finalizare
                comanda</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
export default {
  props: ["quantity"],
  methods: {
    // openModal() {
    //     this.showModal = true;
    // },
    closeModal() {
      this.$store.dispatch("modals/addToCartModal", false);
    },
  },
  computed: {
    product() {
      if(this.$store.getters["modals/showAddToCartModal"] === true){ 
        return this.$store.getters["shop/product"];
      } else { 
        return this.$store.getters["modals/showAddToCartModal"];
      }
      
    },
    cart() {
      return this.$store.getters["shop/cartData"];
    },
    lastCartProduct() {
      return this.$store.getters["shop/lastToCart"];
    },
    showModal() {
      if( this.$store.getters["modals/showAddToCartModal"]) { 
        return true
      } else { 
        return false
      }
    },
    totalQuantity() {
      let quantity = this.cart.positions.find((possition) => {
        return this.product.id === possition.product.id;
      });

      return quantity !== undefined ? quantity : { quantity: 1 };
    },
  },
};
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem 1rem 0 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 440px;
}
.modal__title {
  margin: 0 2rem 0.5rem 0;
  font-weight: 700;
  border-bottom: 1px solid #333;
  font-size: 16px;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.ajax-img-wrapper img {
  max-width: 100%;
}

.ajax-prod-details h4 {
  font-size: 18px;
  line-height: 1.1;
  margin: 10px 0;
}

.ajax-prod-details > div {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 14px;
}

.ajax-label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  padding-right: 10px;
}

.ajax-minicart {
  border-left: 1px solid #ddd;
  background-color: #f3f3f3;
}

#ajax-add-to-cart .modal-dialog {
  width: 920px;
  width: 440px;
  margin: 110px auto;
  padding: 0 10px;
}

label.ajax-label {
  float: left;
  padding-right: 10px;
}
.ajax-modal-title {
  font-weight: bold;
  border-bottom: 1px solid #333;
  font-size: 16px;
}

.subtotal-mini {
  padding: 15px 0px;
  align-items: center;
  font-size: 14px;
}

.s_title,
.s_price,
.s_products {
  letter-spacing: -0.5px;
}

.s_products {
  font-family: Montserrat Light;
  font-size: 11px;
}

.s_price {
  margin-left: auto;
}

#ajax-add-to-cart .btn {
  width: 100%;
  margin-bottom: 15px;
  padding: 11px 0;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
}

.btn.btn-orange {
  background-color: #141619;
  color: #fff;
  font-weight: bold;
  border-radius: 0;
  padding: 6px 30px;
}

#ajax-add-to-cart .fa-angle-left {
  position: absolute;
  left: 30px;
  top: 22%;
  color: #fff;
  font-size: 20px;
}

#ajax-add-to-cart .fa-angle-right {
  position: absolute;
  right: 30px;
  top: 22%;
  color: #fff;
  font-size: 20px;
}

.final-order {
  background-color: #008c00 !important;
}
</style>

<style scoped>
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
